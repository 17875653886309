@import "~bootstrap/scss/bootstrap";
@import "~flatpickr/dist/themes/light.css";


.js-aggressor {
	color: darken($warning, 10%);
}

.typology-tree-list__wrapper {
	padding-left: 0;

	li {
		list-style-type: none;

	  .category-parent {
		padding-left: 1.5rem;
	  }
	}
}

@media print {
	.no-print {
		display: none;
	}

	.navbar {
		.container-fluid {
			justify-content: flex-start;
		}
	}
}

.category-select-all, .category-select-none {
  margin: 0 3px;
  cursor: pointer;
}
